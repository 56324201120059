import React from "react";
import Contact from "../components/KontaktComponents/Contact";
// import MainSection from '../components/KontaktComponents/MainSection'
import { Helmet } from "react-helmet";

export default function Kontakt() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Kontakt | Stragona</title>
        <meta
          name="description"
          content="Skontaktuj się z nami juz dziś! - Formularz kontaktowy - Stragona"
        />
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <Contact showDetails />
    </>
  );
}
