import React, { Component } from "react";

import FormSection from "./Form/FormSection";
import styled from "styled-components";
import PokojeIMG from "../../assets/pokoje.jpg";
import Fade from "react-reveal/Fade";
import ContactComponent from "./ContactComponent";

class Contact extends Component {
  render() {
    return (
      <>
        <Header>
          <h1 className="text">Kontakt</h1>
        </Header>
        <Fade>
          <ContactComponent showDetails={this.props.showDetails} />
        </Fade>
      </>
    );
  }
}

const Header = styled.div`
  height: 55vh;
  background-image: url(${PokojeIMG});
  background-position: center;
  position: relative;
  background-size: cover;

  .text {
    width: 440px;
    position: absolute;
    bottom: 5vh;
    text-align: center;
    font-family: "Josefin Slab", serif;
    font-weight: 200;
    font-size: 80px;
    left: calc(50% - 220px);
    color: white;
    @media (max-width: 1024px) {
      font-size: 46px;
      width: 320px;
      left: calc(50% - 160px);
    }
  }
`;

export default Contact;
